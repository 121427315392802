<template>
    <el-row>
      <el-col :span="24">
        <div class="grid-content ep-bg-purple-dark" />
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
          :style="{ backgroundColor: '#134a7d' }"
        >
          <div style="color: white" class="flex-grow" />
          <el-menu-item v-for="item in menuItems" :key="item.index">
            <router-link :to="item.to" :style="menuLinkStyle"> {{ item.label }}</router-link>
          </el-menu-item>
        </el-menu>
  
        <el-backtop :right="100" :bottom="100" />
      </el-col>
    </el-row>
  
    <router-view />
  </template>
  
  <script>
  import { provide } from 'vue'
  import axios from 'axios'
  axios.defaults.baseURL = '' // 基准路径
  
  export default {
    name: 'App',
    setup() {
      provide('axios', axios)
    },
    data() {
      return {
        menuLinkStyle: {
          fontFamily: 'sans-serif',
          fontSize: '20px',
          color: '#ce9643',
          textDecoration: 'none',
        },
        menuItems: [
          { index: 1, to: '/main', label: 'Introduction' },
          { index: 2, to: '/experimentData', label: 'Sample selection' },
          { index: 4, to: '/experimentCompare', label: 'Sample comparison' },
          { index: 3, to: '/dataPredict', label: 'CMY prediction' },
        ],
      }
    },
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  .router-link-active {
    text-decoration: none;
    color: yellow;
  }
  
  a {
    text-decoration: none;
    color: black;
  }
  </style>
// // 配置路由

import {createRouter, createWebHashHistory} from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import experimentData from "../view/experiment_data";
import experimentCompare from '../view/experiment_compare'
import dataPredict from "../view/data_predict"


const router = createRouter({
    //指定路由工作模式
    history: createWebHashHistory(),
    routes:[
        {path: '/', redirect:'/main'},
        {path:'/experimentData',component:experimentData,},
        {path: '/experimentCompare', component: experimentCompare},
        {path: '/dataPredict', component: dataPredict},
        {path: '/main',component: HelloWorld,meta:{title:"BCBDP"}},
    ],
})

export default router
<template>
    <div style="width: 90%;margin: 0 auto">
        <el-row>
            <el-col :span="24" :offset="0">
                <div class="grid-content ep-bg-purple-dark" style="margin-top: 40px" />
                <el-form :inline="true" :model="formInline" class="demo-form-inline"
                    :style="{ fontFamily: Arial, fontSize: 16 + 'px' }" label-width="auto">
                    <div style="width:80%;margin: 0 auto ;text-align: center;padding-bottom: 15px">
                        <p style="font-size: 20px;font-style: italic;font-weight: 8;font-family: Arial">
                            Methane Yield Prediction
                        </p>
                    </div>
                    <el-form-item label="TS(%)">
                        <el-input-number v-model="formInline.ts" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="VS(%)">
                        <el-input-number v-model="formInline.vs" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item label="VS/TS(%)">
                        <el-input-number v-model="formInline.vs_ts" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="C(%TS)">
                        <el-input-number v-model="formInline.c" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item label="O(%TS)">
                        <el-input-number v-model="formInline.o" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="H(%TS)">
                        <el-input-number v-model="formInline.h" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item label="N(%TS)">
                        <el-input-number v-model="formInline.nn" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="Cellulose Content(%TS)">
                        <el-input-number v-model="formInline.cc" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item label="Hemicellulose Content(%TS)">
                        <el-input-number v-model="formInline.hc" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="Lignin Content(%TS)">
                        <el-input-number v-model="formInline.lc" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />

                    <el-form-item label="Digestion time(days)">
                        <el-input-number v-model="formInline.DT" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="Non-lignocellulosic organic content(%TS)">
                        <el-input-number v-model="formInline.NOC" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item label="Organic loading(g/VS)">
                        <el-input-number v-model="formInline.OL" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <el-form-item label="S/I">
                        <el-input-number v-model="formInline.SI" :precision="2" :step="0.01" :max="1000"
                            placeholder="0.00" />
                    </el-form-item>
                    <br />
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit1">Start</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="24">
                <!-- <span
                    style="font-family: Arial;font-size: 18px;font-weight: bold;color:  #337ecc;text-align:center"></span>
                Predicted Experimental Methane Yield is
                <br /> -->
                <!-- <span style="font-weight: bold;font-family: Arial;font-style: italic;color: #E6A23C;text-align:center">{{
                    result
                }}</span>
                mL/gVS -->
            </el-col>
            <el-col>
                <div ref="main" style="width: 100%;height: 500px"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { reactive } from 'vue'
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
import { ElMessage } from 'element-plus'
//axios.defaults.baseURL = 'http://predict.sch401.xyz/predict'
import * as echarts from 'echarts';

export default {
    name: "dataPredict",
    setup() {
        const formInline = reactive({
            vs: 0,
            ts: 0,
            vs_ts: 0,
            c: 0,
            o: 0,
            h: 0,
            nn: 0,
            cc: 0,
            hc: 0,
            lc: 0,
            DT: 0,
            NOC: 0,
            OL: 0,
            SI: 0
        })

        return {
            formInline
        }
    },
    data() {
        return {
            result: '0.00',
            resultState: 'Success request',
            reurnResult: 0,
            // resList: {
            //     'CMY':[
            //         13,
            //         12,
            //         2,
            //         4,
            //         5
            //     ],
            //     'time':[
            //         1,
            //         2,
            //         3,
            //         4,
            //         5
            //     ]
            // }
            resList: {}
        }
    },
    methods: {
        onSubmit1() {
            if (this.reurnResult === 1) {
                ElMessage.error("Calculating, please do not click again!")
                return
            }
            this.result = '0.00'
            var axiosData = toRaw(this.formInline)
            //var num = 0
            for (var i in axiosData) {
                if (axiosData[i] === 0.00) {
                    ElMessage.error("Sorry, Please enter data!")
                    return
                }
            }
            this.torun(axiosData)

            // console.log(runResult)
            // this.result = runResult['']
        },
        async torun(parameter) {
            this.reurnResult = 1
            let datapara = new FormData();
            datapara.append('TS(%)', parameter['ts']);
            datapara.append('VS(%)', parameter['vs']);
            datapara.append('VS/TS(%)', parameter['vs_ts']);
            datapara.append('C(%TS)', parameter['c']);
            datapara.append('O(%TS)', parameter['o']);
            datapara.append('H(%TS)', parameter['h']);
            datapara.append('N(%TS)', parameter['nn']);
            datapara.append('CC(%TS)', parameter['cc']);
            datapara.append('HC(%TS)', parameter['hc']);
            datapara.append('LC(%TS)', parameter['lc']);
            datapara.append('DT', parameter['DT']);
            datapara.append('NOC', parameter['NOC']);
            datapara.append('OL', parameter['OL']);
            datapara.append('S/I)', parameter['SI']);
            let thedata = await axios({
                method: "post",
                url: "/api/predict",
                data: datapara,
                header: {
                    'Content-Type': "multipart/form-data, charset=UTF-8"
                }
            }).catch(error => {
                ElMessage.error("Sorry, request failure!")
                console.log(error)
            });
            this.reurnResult = 0
            this.result = thedata['data']
            // console.log(JSON.parse(JSON.stringify(this.resList['time'])))
            // console.log(JSON.parse(JSON.stringify(this.resList['CMY'])))
            this.resList = thedata['data']
            if (Object.keys(this.resList).length !== 0) {
                let myChart = echarts.init(this.$refs.main)
                var option = {
                    xAxis: {
                        type: 'category',
                        data: JSON.parse(JSON.stringify(this.resList['time'])),
                        name: 'Digestion time/day',
                        nameLocation: "middle",
                        nameTextStyle: {//字体样式
                            fontSize: 16,//字体大小
                            padding: 20 //距离坐标位置的距离
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Cumulative methane yield/(mL/gVS)',
                        nameLocation: "middle",
                        nameTextStyle: {//字体样式

                            fontSize: 16,//字体大小

                            padding: 20 //距离坐标位置的距离

                        },
                    },
                    series: [
                        {
                            data: JSON.parse(JSON.stringify(this.resList['CMY'])),
                            type: 'line',
                            smooth: true
                        }
                    ]
                };
                option && myChart.setOption(option);
            }
        }

    }
}
</script>

<style scoped>
.el-form-item__label {
    text-align: justify;
}
</style>

<template>
    <div style="width: 76%;margin: 0 auto">
        <el-row>
            <el-col :span="24">
                <!-- <div class="grid-content ep-bg-purple-dark" /> -->
                <div aria-label="A complete example of page header">
                    <el-col :span="6">
                        <div style="width: 100%;height:auto"><img alt="logo" src="../assets/buct.webp"
                                style="float:left;height: 48px;">
                            <p style="color: #ce9643;font-size:40px;text-align: left;">&#160;&#160;BCBDP</p>
                        </div>
                    </el-col>
                    <el-divider content-position="left"></el-divider>


                    <el-descriptions :column="3" size="small" class="mt-4">
                        <el-descriptions-item label="">
                            <el-tag size="large ">
                                <p style="font-size: 18px">BCBPD</p>
                            </el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item label=" "></el-descriptions-item>
                        <el-descriptions-item label=" "></el-descriptions-item>
                        <el-descriptions-item label="">
                            <span style="font-size: 18px;font-style: oblique">Biomass Characteristics and Biomethane
                                Production Database</span>
                        </el-descriptions-item>
                        <el-descriptions-item label=""></el-descriptions-item>
                    </el-descriptions>
                    <p class="mt-4 text-sm" style="font-size: 16px;display:inline-block;width:98%;text-align:left;
font-family: Tahoma;color: #333;line-height: 24px">
                        Lignocellulosic waste is one of the most abundant biowaste produced from agriculture,
                        forestry and urban life. It can be converted into methane through anaerobic digestion.
                        However, the physical and chemical characteristics of various LWs can be quite different,
                        resulting in different energy conversion efficiency. The biochemical methane potential
                        test is a standard method to determine the maximum methane yield of a feedstock. BCBPD includes
                        157
                        lignocellulosic wastes in terms of the physicochemical characteristics and methane production
                        performance.
                        Besides, a data-driven approach is also built for predicting methane production of LW.
                    </p>
                    <el-divider content-position="left"><span
                            style="font-family: ScalaOT Regular, ScalaOT;color: #666;font-size: 20px"></span></el-divider>
                    <p class="mt-4 text-sm"
                        style="font-family: Tahoma, Geneva,sans-serif;line-height: 24px;font-size: 16px;display:inline-block;width:100%;text-align:left;">
                        The BCBPD is an integrated platform for:</p>
                    <span class="mt-4 text-sm"
                        style="font-family: Tahoma, Geneva,sans-serif;line-height: 24px;font-size: 16px;display:inline-block;width:100%;text-align:left;">
                        - Searching and comparing the basic characteristics and methane production performance of 157
                        lignocellulosic wastes (to be continuously updated)
                    </span>
                    <span class="mt-4 text-sm"
                        style="font-family: Tahoma, Geneva,sans-serif;line-height:24px;font-size: 16px;display:inline-block;width:100%;text-align:left;">
                        - Predicting the methane production of LW not collected in the BCBPD using machine learning model
                        built by TPOT
                    </span>
                </div>
                <div style="width: 100%;margin-top: 18px"></div>
                <!-- <div>
                    <img alt="Experiment" src="../assets/图片1.webp" style="height:auto;max-width:100%">
                </div>
                <div style="width: 100%;margin-top: 18px;font-size:5px">
                    * TS: Total Solid, VS: Volatile Solid, C: Carbon Content, H: Hydrogen Content, O: Oxygen Content, N:
                    Nitrogen Content, CC: Cellulose Content, HC: Hemicellulose Content, LC: Lignin Content, NOC:
                    Non-lignocellulosic Organic Content, OL: Organic Loading, S/I: Substrate to Inoculum Ratio
                </div> -->
            </el-col>
            <el-divider content-position="left"><span style="font-family: ScalaOT Regular, ScalaOT;color: #666"></span>
            </el-divider>
            <el-col :span="24" :style="{ marginTop: 8 + 'px' }">
                <p style="font-family: Verdana;font-size: 16px;text-align:center;">We appreciate and
                    welcome all
                    the uses and
                    citations of this database. If you have any suggestions or would like to share new data,
                    please contact Prof. Chang Chen by mailing
                    to <a href="mailto:chenchang@mail.buct.edu.cn"
                        style="font-style: italic;text-decoration: none">chenchang@mail.buct.edu.cn</a>.
                </p>
                <div>
                    <el-col :span="24">
                        <div class="grid-content ep-bg-purple-dark" />

                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content ep-bg-purple-dark" />
                        <p style="font-family: Verdana;font-size: 16px;text-align:left;">Please cite this work if this
                            BCBPD is adopted in scientific paper:
                        </p>
                    </el-col>
                    <!-- <el-divider content-position="left"><span
                            style="font-family: ScalaOT Regular, ScalaOT;color: #666"></span>
                    </el-divider> -->


                    <span
                        style="font-size: 16px;font-weight:8;font-style:italic;color: rgba(102, 102, 102, 1);line-height: 22px">
                        <a href="https://doi.org/10.1016/j.biortech.2023.129953" target="_blank">Song, C., Cai, F., Yang,
                            S., Wang, L., Liu, G., & Chen, C. (2024). Machine learning-based prediction of methane
                            production from lignocellulosic wastes. Bioresource Technology, 393, 129953.
                        </a>
                        <span style="font-family: ScalaOT Regular, ScalaOT;color: #666"></span>
                    </span>
                </div>
            </el-col>
        </el-row>
    </div>
    <div>
        <p><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022003999号</a></p>
        <p><img src="../assets/policelogo.png" style="height:auto;">京公网安备 11010502051410号</p>
    </div>
</template>





<style>
.flex-grow {
    flex-grow: 1;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

.router-link-active {
    text-decoration: none;
    color: yellow;
}

a {
    text-decoration: none;
    color: black;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import './index.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'




router.beforeEach((to) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
})
const app = createApp(App)
app.use(ElementPlus, {locale: zhCn})
app.use(router)
app.mount('#app')



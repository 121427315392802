<template>
  <div style="width: 90%;margin: 0 auto">
    <el-row>
      <div style="width: 100%;margin-top: 9px"></div>
      <el-col :span="24">
        <el-row :gutter="0">
          <span style="font-size: 20px;font-style: italic;font-weight: 8">Please select the sample:
          </span>
        </el-row>
      </el-col>
      <el-col :span="6">
        <div class="grid-content ep-bg-purple" />
        <el-cascader :style="{ width: '800px', minWidth: 100 + 'px', maxWidth: 2000 + 'px' }"
          placeholder="Please select a sample" id="width_cascader" :options="options" clearable @change="handleChange"
          ref="cascaderAddr" />
      </el-col>
      <el-col :span="24">
        <div class="grid-content ep-bg-purple-dark" />
        <div style="width: 100%;text-align: left;margin-bottom: 8px;">
          <el-tag class="ml-2" type="warning" style="font-size: 14px">Physicochemical Characteristics</el-tag>
        </div>
        <div class="check-boder" style="padding-left: 5px">
          <el-checkbox-group v-model="checkedVarx1" @change="handleCheckedVarxChange">
            <el-checkbox v-for="var_ in varx" :key="var_" :label="var_"
              :style="{ fontSize: 16 + 'px', fontFamily: sans - serif }">{{
                  var_
              }}</el-checkbox>
            <br />
          </el-checkbox-group>
        </div>
      </el-col>

      <el-col :span="24">
        <div class="grid-content ep-bg-purple-dark" />
        <div style="width: 100%;text-align: left;margin-bottom: 8px">
          <el-tag class="ml-2" type="warning" style="font-size: 14px">Operation Parameter</el-tag>
        </div>
        <div class="check-boder" style="padding-left: 5px">
          <el-checkbox-group v-model="checkedVarx2" @change="handleCheckedVarxChange2">
            <el-checkbox v-for="var_2 in varx2" :key="var_2" :label="var_2"
              :style="{ fontSize: 16 + 'px', fontFamily: sans - serif }">{{
                  var_2
              }}</el-checkbox>
            <br />
          </el-checkbox-group>
        </div>
      </el-col>

      <el-col :span="24">
        <div class="grid-content ep-bg-purple-dark" />
        <el-descriptions class="margin-top" :title="dataTitle" :column="3" :size="size" :style="blockMargin">
          <el-descriptions-item v-for="(value, index) in htmlData" v-bind:key="index" :label="value.namex"
            :style="{ fontSize: 16 + 'px', fontFamily: Verdana, fontStyle: oblique }">
            <el-tag size="small" :style="{ fontSize: 14 + 'px', fontFamily: Verdana, fontStyle: oblique }">{{
                value.value
            }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="margin-top" :title="dataTitle2" :column="3" :size="size" :style="blockMargin">
          <el-descriptions-item v-for="(value, index) in htmlData2" v-bind:key="index" :label="value.namex"
            :style="{ fontSize: 16 + 'px', fontFamily: Verdana, fontStyle: oblique }">
            <el-tag size="small" :style="{ fontSize: 14 + 'px', fontFamily: Verdana, fontStyle: oblique }">{{
                value.value
            }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      &nbsp;
      <el-col :span="24" :offset="3">
        <div ref="dataChart" style="width: 84%;height: 600px"></div>
        <div style="width: 84%">
          <a :href="Doi" target="view_window"
            style="text-decoration: none;font-style: italic;font-family: Verdana;font-size: 14px;">{{
    Papertitle
            }}</a>
        </div>

      </el-col>

    </el-row>
  </div>


</template>

<script >
import * as echarts from 'echarts' //局部引入
import { ElMessage } from 'element-plus'
import { ref } from "vue"
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
//import Qs from 'qs'
const isIndeterminate = ref(true)
//map映射
const m1 = new Map([
  ['TS(%)', 'ts'],
  ['VS(%)', 'vs'],
  ['VS/TS(%)', 'vsDivTs'],
  ['O(%TS)', 'indexO'],
  ['C(%TS)', 'indexC'],
  ['H(%TS)', 'indexH'],
  ['N(%TS)', 'indexN'],
  ['Cellulose Content (%TS)', 'celluloseContent'],
  ['Hemicellulose Content (%TS)', 'hemicelluloseContent'],
  ['Lignin Content (%TS)', 'ligninContent'],
  ['Non-lignocellulosic Organic Content (%TS)', 'nonLignocellulosicOrganicContent'],
  ['Non-structural Carbohydrate (%TS)', 'nonStructuralCarbohydrate'],
  ['Protein (%TS)', 'protein'],
  ['Volatile Fatty Acid (%TS)', 'volatileFattyAcid'],
  ['Organic Loading (g/L)', 'organicLoading'],
  ['S/I', 'sdiv'],
  ['Theoretical Methane Yield (mL/gVS)', 'theoreticalMethaneYield'],
  ['Experimental Methane Yield (mL/gVS)', 'experimentalMethaneYield'],
  ['Digestion time (d)', 'digestionTime'],
]);
const m2 = new Map([
  ['day1', '1'],
  ['day2', '2'],
  ['day3', '3'],
  ['day4', '4'],
  ['day5', '5'],
  ['day6', '6'],
  ['day7', '7'],
  ['day8', '8'],
  ['day9', '9'],
  ['day10', '10'],
  ['day11', '11'],
  ['day12', '12'],
  ['day13', '13'],
  ['day14', '14'],
  ['day15', '15'],
  ['day16', '16'],
  ['day17', '17'],
  ['day18', '18'],
  ['day19', '19'],
  ['day20', '20'],
  ['day21', '21'],
  ['day22', '22'],
  ['day23', '23'],
  ['day24', '24'],
  ['day25', '25'],
  ['day26', '26'],
  ['day27', '27'],
  ['day28', '28'],
  ['day29', '29'],
  ['day30', '30'],
  ['day31', '31'],
  ['day32', '32'],
  ['day33', '33'],
  ['day34', '34'],
  ['day35', '35'],
  ['day36', '36'],
  ['day37', '37'],
  ['day38', '38'],
  ['day39', '39'],
  ['day40', '40'],
  ['day41', '41'],
  ['day42', '42'],
  ['day43', '43'],
  ['day44', '44'],
  ['day45', '45'],
  ['day46', '46'],
  ['day47', '47'],
  ['day48', '48'],
  ['day49', '49'],
  ['day50', '50'],
  ['day51', '51'],
  ['day52', '52'],
  ['day53', '53'],
]);


export default {
  name: "experimentData",
  setup() {
    const visible = ref(false)
    const options = [
      {
        value: "category",
        label: "Agricultural crop straws",
        children: [
          {
            value: "common_name",
            label: "Corn stover",
            children: [
              {
                value: "latin_name",
                label: "Zea mays",

              },
            ],
          },
          {
            value: "common_name",
            label: "Cotton stalk",
            children: [
              {
                value: "latin_name",
                label: "Gossypium herbaceum",

              },
            ],
          },
          {
            value: "common_name",
            label: "Rice husk",
            children: [
              {
                value: "latin_name",
                label: "Rice husk",

              },
            ],
          },
          {
            value: "common_name",
            label: "Rice straw",
            children: [
              {
                value: "latin_name",
                label: "Oryza sativa",

              },
            ],
          },
          {
            value: "common_name",
            label: "Wheat straw",
            children: [
              {
                value: "latin_name",
                label: "Triticum aestivum",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Animal manures",
        children: [
          {
            value: "common_name",
            label: "Chicken manure",
            children: [
              {
                value: "latin_name",
                label: "Chicken manure",

              },
            ],
          },
          {
            value: "common_name",
            label: "Dairy manure",
            children: [
              {
                value: "latin_name",
                label: "Dairy manure",

              },
            ],
          },
          {
            value: "common_name",
            label: "Swine manure",
            children: [
              {
                value: "latin_name",
                label: "Swine manure",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Energy grass",
        children: [
          {
            value: "common_name",
            label: "Pennisetum giganteum",
            children: [
              {
                value: "latin_name",
                label: "Pennisetum giganteum z.x.lin",
                children: [
                  { value: "option_one", label: "Collected in December" },
                  { value: "option_one", label: "Collected in July" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Switchgrass",
            children: [
              {
                value: "latin_name",
                label: "Panicum virgatum",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Food wastes",
        children: [
          {
            value: "common_name",
            label: "Kitchen waste",
            children: [
              {
                value: "latin_name",
                label: "Kitchen waste",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Fruit wastes",
        children: [
          {
            value: "common_name",
            label: "Avocado",
            children: [
              {
                value: "latin_name",
                label: "Persea america",
                children: [{ value: "option_one", label: "Peel" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Cherry",
            children: [
              {
                value: "latin_name",
                label: "Cerasus pseudocerasus",
                children: [{ value: "option_one", label: "Seed" }],
              },
              {
                value: "latin_name",
                label: "Prunus avium",
                children: [{ value: "option_one", label: "Leaf" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Coconut",
            children: [
              {
                value: "latin_name",
                label: "Cocos nucifera",
                children: [
                  { value: "option_one", label: "Coat" },
                  { value: "option_one", label: "Shell" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Durian",
            children: [
              {
                value: "latin_name",
                label: "Durio zibethinus",
                children: [
                  { value: "option_one", label: "Seed" },
                  { value: "option_one", label: "Shell" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Grape",
            children: [
              {
                value: "latin_name",
                label: "Vitis vinifera",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Grapefruit",
            children: [
              {
                value: "latin_name",
                label: "Citrus maxima",
                children: [{ value: "option_one", label: "Peel" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Horned melon",
            children: [
              {
                value: "latin_name",
                label: "Cucumis metuliferus",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Jackfruit",
            children: [
              {
                value: "latin_name",
                label: "Artocarpus heterophyllus",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Litchi",
            children: [
              {
                value: "latin_name",
                label: "Litchi chinensis",
                children: [
                  { value: "option_one", label: "Peel" },
                  { value: "option_one", label: "Seed" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Longan",
            children: [
              {
                value: "latin_name",
                label: "Dimocarpus longan",
                children: [
                  { value: "option_one", label: "Peel" },
                  { value: "option_one", label: "Seed" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Loquat",
            children: [
              {
                value: "latin_name",
                label: "Eriobotrya japonica",
                children: [
                  { value: "option_one", label: "Peel" },
                  { value: "option_one", label: "Seed" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Mangosteen",
            children: [
              {
                value: "latin_name",
                label: "Garcinia mangosta",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Mixed fruit and vegetable waste",
            children: [
              {
                value: "latin_name",
                label: "Mixed  Fruit and vegetable waste",

              },
            ],
          },
          {
            value: "common_name",
            label: "Passionfruit",
            children: [
              {
                value: "latin_name",
                label: "Passiflora coerulea",
                children: [{ value: "option_one", label: "Peel" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pear",
            children: [
              {
                value: "latin_name",
                label: "Pyrus communis",
                children: [{ value: "option_one", label: "Leaf" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pitaya",
            children: [
              {
                value: "latin_name",
                label: "Hylocereus undulatus",
                children: [{ value: "option_one", label: "Peel" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Rambutan",
            children: [
              {
                value: "latin_name",
                label: "Nephelium lappaceum",
                children: [
                  { value: "option_one", label: "Seed" },
                  { value: "option_one", label: "Shell" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Rubber husk",
            children: [
              {
                value: "latin_name",
                label: "Hevea brasiliensis",
                children: [{ value: "option_one", label: "Husk" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Strawberry",
            children: [
              {
                value: "latin_name",
                label: "Fragaria x ananassa",
                children: [{ value: "option_one", label: "Leaf" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Sugar-apple",
            children: [
              {
                value: "latin_name",
                label: "Anno squamosa",
                children: [{ value: "option_one", label: "Peel" }],
              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Municipal wastes",
        children: [
          {
            value: "common_name",
            label: "Abaca",
            children: [
              {
                value: "latin_name",
                label: "Musa textilis",

              },
            ],
          },
          {
            value: "common_name",
            label: "Blue denim",

          },
          {
            value: "common_name",
            label: "Coffee",
            children: [
              {
                value: "latin_name",
                label: "Coffea arabica",
                children: [
                  { value: "option_one", label: "Husk" },
                  { value: "option_one", label: "Parchment" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Corrugated board",

          },
          {
            value: "common_name",
            label: "Cotton",
            children: [
              {
                value: "latin_name",
                label: "Gossypium herbaceum",

              },
            ],
          },
          {
            value: "common_name",
            label: "Flax",
            children: [
              {
                value: "latin_name",
                label: "Linum usitatissimum",

              },
            ],
          },
          {
            value: "common_name",
            label: "Hemp",
            children: [
              {
                value: "latin_name",
                label: "Canbis sativa",

              },
            ],
          },
          {
            value: "common_name",
            label: "Jute",
            children: [
              {
                value: "latin_name",
                label: "Corchorus olitorius",

              },
            ],
          },
          {
            value: "common_name",
            label: "Kapok",
            children: [
              {
                value: "latin_name",
                label: "Ceiba pentandra",

              },
            ],
          },
          {
            value: "common_name",
            label: "Magazine paper",

          },
          {
            value: "common_name",
            label: "Office paper",

          },
          {
            value: "common_name",
            label: "Ramie",
            children: [
              {
                value: "latin_name",
                label: "Boehmeria nivea",

              },
            ],
          },
          {
            value: "common_name",
            label: "Rayon",

          },
          {
            value: "common_name",
            label: "Sisal",
            children: [
              {
                value: "latin_name",
                label: "Agave sisala",

              },
            ],
          },
          {
            value: "common_name",
            label: "Tissue paper",

          },
          {
            value: "common_name",
            label: "White denim",

          },
          {
            value: "common_name",
            label: "Yard waste",
            children: [
              {
                value: "latin_name",
                label: "Yard waste",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Nut wastes",
        children: [
          {
            value: "common_name",
            label: "Almond",
            children: [
              {
                value: "latin_name",
                label: "Prunus amygdalus",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Bitter apricot kernel",
            children: [
              {
                value: "latin_name",
                label: "Amygdalus communis",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Chestnut",
            children: [
              {
                value: "latin_name",
                label: "Castanea mollissima",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Ginkgo",
            children: [
              {
                value: "latin_name",
                label: "Ginkgo biloba",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Hazelnut",
            children: [
              {
                value: "latin_name",
                label: "Corylus heterophylla",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Hickory nut",
            children: [
              {
                value: "latin_name",
                label: "Carya cathayensis",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Macadamia nut",
            children: [
              {
                value: "latin_name",
                label: "Macadamia ternifolia",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Peanut",
            children: [
              {
                value: "latin_name",
                label: "Arachis hypogaea",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pecan",
            children: [
              {
                value: "latin_name",
                label: "Carya illinoinensis",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pine nut",
            children: [
              {
                value: "latin_name",
                label: "Pinus koreaiensis",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pistachio",
            children: [
              {
                value: "latin_name",
                label: "Pistacia vera",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Sunflower",
            children: [
              {
                value: "latin_name",
                label: "Helianthus annuus",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Walnut",
            children: [
              {
                value: "latin_name",
                label: "Juglans regia",
                children: [
                  { value: "option_one", label: "Shell" },
                  { value: "option_one", label: "Skin" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Water chestnut",
            children: [
              {
                value: "latin_name",
                label: "Trapa bispinosa",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Watermelon",
            children: [
              {
                value: "latin_name",
                label: "Citrullus latus",
                children: [{ value: "option_one", label: "Shell" }],
              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Processing organic wastes",
        children: [
          {
            value: "common_name",
            label: "Goosefoots",
            children: [
              {
                value: "latin_name",
                label: "Chenopodium album",
                children: [
                  { value: "option_one", label: "Seed" },
                  { value: "option_one", label: "Stalk" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Vinegar residue",
            children: [
              {
                value: "latin_name",
                label: "Vinegar residue",

              },
            ],
          },
        ],
      },
      {
        value: "category",
        label: "Vegetable wastes",
        children: [
          {
            value: "common_name",
            label: "Amaranth wood",
            children: [
              {
                value: "latin_name",
                label: "Amaranthus tricolor",

              },
            ],
          },
          {
            value: "common_name",
            label: "Baby pumpkins",
            children: [
              {
                value: "latin_name",
                label: "Cucurbita pepo",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Bell pepper",
            children: [
              {
                value: "latin_name",
                label: "Capsicum annuum",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
              {
                value: "latin_name",
                label: "Capsicum annuum var. Grossum",
                children: [
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Bitter melon",
            children: [
              {
                value: "latin_name",
                label: "Momordica charantia",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Bok choy",
            children: [
              {
                value: "latin_name",
                label: "Brassica chinensis var. Chinensis",

              },
            ],
          },
          {
            value: "common_name",
            label: "Broccoli",
            children: [
              {
                value: "latin_name",
                label: "Brassica oleracea var. Italica",

              },
            ],
          },
          {
            value: "common_name",
            label: "Cabbage",
            children: [
              {
                value: "latin_name",
                label: "Brassica oleracea var. Capitata",

              },
            ],
          },
          {
            value: "common_name",
            label: "Carob",
            children: [
              {
                value: "latin_name",
                label: "Vig unguiculata",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Carrot",
            children: [
              {
                value: "latin_name",
                label: "Daucus carota var. Sativa",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Cauliflower",
            children: [
              {
                value: "latin_name",
                label: "Brassica oleracea var. Botrytis",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Celery",
            children: [
              {
                value: "latin_name",
                label: "Apium graveolens",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
              {
                value: "latin_name",
                label: "Oenthe javanica",

              },
            ],
          },
          {
            value: "common_name",
            label: "Cherry tomato",
            children: [
              {
                value: "latin_name",
                label: "Lycopersicon esculentum var. Cerasiforme",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Chinese cabbage",
            children: [
              {
                value: "latin_name",
                label: "Brassica chinensis",

              },
              {
                value: "latin_name",
                label: "Brassica pekinensis",

              },
            ],
          },
          {
            value: "common_name",
            label: "Chinese flowering cabbage (choy sum)",
            children: [
              {
                value: "latin_name",
                label: "Brassica rapa var. Parachinensis",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Chinese toon sprout",
            children: [
              {
                value: "latin_name",
                label: "Too sinensis",
                children: [{ value: "option_one", label: "Sprout" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Chinese yam",
            children: [
              {
                value: "latin_name",
                label: "Dioscorea polystachya",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Common andrographis",
            children: [
              {
                value: "latin_name",
                label: "Andrographis paniculata",

              },
            ],
          },
          {
            value: "common_name",
            label: "Coriander",
            children: [
              {
                value: "latin_name",
                label: "Coriandrum sativum",

              },
            ],
          },
          {
            value: "common_name",
            label: "Crown daisy",
            children: [
              {
                value: "latin_name",
                label: "Chrysanthemum cororium",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Cucumber",
            children: [
              {
                value: "latin_name",
                label: "Cucumis sativus",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Eggplant",
            children: [
              {
                value: "latin_name",
                label: "Solanum melonge",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Fig",
            children: [
              {
                value: "latin_name",
                label: "Ficus carica",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Garlic",
            children: [
              {
                value: "latin_name",
                label: "Allium sativum",
                children: [{ value: "option_one", label: "Shoot" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Green soybean",
            children: [
              {
                value: "latin_name",
                label: "Glycine max",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Kidney bean",
            children: [
              {
                value: "latin_name",
                label: "Phaseolus vulgaris",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "King white mulberry",
            children: [
              {
                value: "latin_name",
                label: "Morus macroura",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Leek",
            children: [
              {
                value: "latin_name",
                label: "Allium tuberosum",

              },
            ],
          },
          {
            value: "common_name",
            label: "Lentil",
            children: [
              {
                value: "latin_name",
                label: "Lablab purpureus",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Lettuce",
            children: [
              {
                value: "latin_name",
                label: "Lactuca sativa",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Loofah",
            children: [
              {
                value: "latin_name",
                label: "Luffa aegyptiaca",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Luffa",
            children: [
              {
                value: "latin_name",
                label: "Luffa aegyptiaca",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Melon",
            children: [
              {
                value: "latin_name",
                label: "Cucumis melo",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Mint",
            children: [
              {
                value: "latin_name",
                label: "Mentha haplocalyx",

              },
            ],
          },
          {
            value: "common_name",
            label: "Mung bean",
            children: [
              {
                value: "latin_name",
                label: "Vig radiata",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Onion",
            children: [
              {
                value: "latin_name",
                label: "Allium cepa",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Peanut",
            children: [
              {
                value: "latin_name",
                label: "Arachis hypogaea",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Potato",
            children: [
              {
                value: "latin_name",
                label: "Solanum tuberosum",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Pumpkin",
            children: [
              {
                value: "latin_name",
                label: "Cucurbita moschata",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Purple cabbage",
            children: [
              {
                value: "latin_name",
                label: "Brassica campestris var. Purpuraria",

              },
            ],
          },
          {
            value: "common_name",
            label: "Red bean",
            children: [
              {
                value: "latin_name",
                label: "Vig angularis",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Red chili",
            children: [
              {
                value: "latin_name",
                label: "Capsicum cultivars",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Red radish",
            children: [
              {
                value: "latin_name",
                label: "Raphanus sativus",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Romaine lettuce",
            children: [
              {
                value: "latin_name",
                label: "Lactuca sativa var. Ramosa",

              },
            ],
          },
          {
            value: "common_name",
            label: "Round eggplant",
            children: [
              {
                value: "latin_name",
                label: "Solanum melonge var. Esculentum",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Root" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Schizonepeta",
            children: [
              {
                value: "latin_name",
                label: "Nepeta cataria",

              },
            ],
          },
          {
            value: "common_name",
            label: "Sharp pepper",
            children: [
              {
                value: "latin_name",
                label: "Capsicum frutescens",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Sharp pepper root",
            children: [
              {
                value: "latin_name",
                label: "Capsicum frutescens",
                children: [{ value: "option_one", label: "Root" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Snow peas",
            children: [
              {
                value: "latin_name",
                label: "Pisum sativum var. Saccharatum",
                children: [{ value: "option_one", label: "Leaf" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Spich",
            children: [
              {
                value: "latin_name",
                label: "Spicia oleracea",

              },
            ],
          },
          {
            value: "common_name",
            label: "Summer radish",
            children: [
              {
                value: "latin_name",
                label: "Scrophularia ningpoensis",

              },
            ],
          },
          {
            value: "common_name",
            label: "Sweet potato",
            children: [
              {
                value: "latin_name",
                label: "Ipomoea batatas",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Tomato",
            children: [
              {
                value: "latin_name",
                label: "Solanum lycopersicum",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                  { value: "option_one", label: "Whole" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Turnip",
            children: [
              {
                value: "latin_name",
                label: "Brassica rapa",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Water spich",
            children: [
              {
                value: "latin_name",
                label: "Ipomoea aquatica",

              },
            ],
          },
          {
            value: "common_name",
            label: "Wax gourd",
            children: [
              {
                value: "latin_name",
                label: "Benincasa hispida",
                children: [{ value: "option_one", label: "Whole" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Wild cabbage",
            children: [
              {
                value: "latin_name",
                label: "Brassica oleracea",
                children: [{ value: "option_one", label: "Leaf" }],
              },
            ],
          },
          {
            value: "common_name",
            label: "Winter melon",
            children: [
              {
                value: "latin_name",
                label: "Benincasa hispida",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
          {
            value: "common_name",
            label: "Zucchini",
            children: [
              {
                value: "latin_name",
                label: "Cucurbita pepo",
                children: [
                  { value: "option_one", label: "Leaf" },
                  { value: "option_one", label: "Stem" },
                ],
              },
            ],
          },
        ],
      },
    ];



    const varx = ['TS(%)', 'VS(%)', 'VS/TS(%)', 'C(%TS)', 'O(%TS)', 'H(%TS)', 'N(%TS)', 'Cellulose Content (%TS)',
      'Hemicellulose Content (%TS)', 'Lignin Content (%TS)', 'Non-lignocellulosic Organic Content (%TS)',
      'Non-structural Carbohydrate (%TS)', 'Protein (%TS)', 'Volatile Fatty Acid (%TS)', 'Theoretical Methane Yield (mL/gVS)']
    const varx2 = ['Organic Loading (g/L)',
      'S/I', 'Digestion time (d)',]//,'Experimental Methane Yield (mL/gVS)'
    const checkAll = ref(false)
    const checkedVarx = ref([])
    const checkedVarx1 = ref([])
    const checkedVarx2 = ref([])




    return {
      options, visible, varx, varx2, checkAll, checkedVarx, checkedVarx1, checkedVarx2
    }
  },
  created() {
    if (this.checkedVarx.length > 0) {
      var tempL = []
      this.checkedVarx.forEach(function (value) {
        tempL.push({
          namex: value,
          value: "none"//m1.get(value)+" test"
        })
      })
      this.htmlData = tempL
    }
    if (this.htmlData.length > 0) {
      this.dataTitle = "EXPERIMENTAL DATA"
      //if 判断是否有数据数据，设置
      this.isDisable = false
    }
  },
  data() {
    return {
      htmlData: [],
      htmlData2: [],
      dataTitle: "",
      dataTitle2: "",
      isDisable: true, //禁用状态
      isCheckAll: false,//是否全选
      dataOne: {},
      dataTwo: {},
      dataThree: {},
      width: 300,
      thsAreaCode: [],
      Papertitle: '',
      Doi: ''
    }
  },
  mounted() {

  },
  methods: {
    handleChange() {
      this.checkedVarx1 = []
      this.checkedVarx2 = []
      // var el_cascader_element = document.getElementById('#width_cascader')
      // el_cascader_element.style.width = "100px"
      this.thsAreaCode = []
      var thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()//获取级联选项框的值
      var selected = thsAreaCode[0].pathLabels
      var tempLength = 0
      for (var i = 0, l = selected.length; i < l - 1; i++) {
        tempLength = tempLength + selected[i].length
      }
      this.width = tempLength * 12
      this.thsAreaCode = thsAreaCode

      this.dataOne = {}
      this.dataTwo = {}
      this.dataThree = {}
      this.htmlData = []
      this.htmlData2 = []

      //this.getecharts(thsAreaCode)
    },
    async getdata(selected) {
      // var thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()//获取级联选项框的值
      // var temp = thsAreaCode[0]
      // var paramList = temp.pathLabels
      var paramList = selected
      //let thedata = await axios({url:"localhost:8080/api/data"})//localhost:8080/api/data
      let thedata = await axios({
        method: "get",
        url: "/api/data", //"https://tenapi.cn/comment/",//
        parameters: {
          category: paramList[0],
          common_name: paramList[1],
          latin_name: paramList[2],
          optionOne: "0"
        }
      }).catch(error => {
        console.log("catch");
        console.log(error)
      });//发送get请求
      return thedata
    },
    async getecharts(selected) {
      this.checkedVarx1 = []
      this.checkedVarx2 = []
      this.Papertitle = ''
      this.Doi = ''
      //var thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()//获取级联选项框的值
      var thsAreaCode = selected
      if (thsAreaCode.length == 0) {
        ElMessage({
          message: "Warning, you haven not selected.",
          type: 'warning',
        })
      } else {
        var temp = thsAreaCode[0]
        var paramList = toRaw(temp.pathLabels)



        let datapara = new FormData();
        datapara.append('category', paramList[0].toString());
        datapara.append('commonName', paramList[1].toString())
        //datapara.append('latinName', paramList[2].toString());

        if (paramList.length < 3) {
          datapara.append('optionOne', '0')
          datapara.append('latinName', '0');
        } else if (paramList.length === 3) {
          datapara.append('latinName', paramList[2].toString());
          datapara.append('optionOne', '0');
        } else {
          datapara.append('latinName', paramList[2].toString());
          datapara.append('optionOne', paramList[3].toString())
        }


        // datapara.append('optionOne',paramList[3].toString())
        let thedata = await axios({
          method: "post",
          url: "/api/data",
          data: datapara,
          header: {
            'Content-Type': "multipart/form-data, charset=UTF-8"
          }
        }).catch(error => {
          ElMessage.error("Sorry, Query failed!")
          console.log(error)
        });//发送post请求
        thedata = thedata["data"]


        let validData = Object.values(thedata)
        if (validData[0] == null) {
          ElMessage({
            type: 'info',
            message: 'Sorry. There is no data!',
          })
          return
        }

        let myecharts = echarts.init(this.$refs.dataChart)


        //get three data and assignment parameter
        var arrayData = Object.values(thedata)
        this.dataOne = arrayData[0]
        this.dataTwo = arrayData[1]
        this.dataThree = arrayData[2]
        // end


        //dataTwo dataThree and show echarts
        var xaxis = Object.keys(this.dataTwo)
        xaxis = xaxis.slice(2, 55)
        for (var x = 0, le = xaxis.length; x < le; x++) {
          xaxis[x] = m2.get(xaxis[x])
        }
        //

        var yaxis = Object.values(this.dataTwo)
        this.Papertitle = 'Paper link: ' + yaxis[0]
        this.Doi = yaxis[1]
        yaxis = yaxis.slice(2, 55)
        if (yaxis[0] === -1) {
          return
        }
        var temp_yaxis = []
        var index = []
        for (var r = 0, len2 = yaxis.length; r < len2; r++) {
          if (yaxis[r] !== -1) {
            temp_yaxis.push(yaxis[r])
            index.push(r)
          }
        }
        yaxis = temp_yaxis
        if (index.length === 0) {
          return
        }

        var temp_xaxis = []
        for (var t = 0, len = index.length; t < len; t++) {
          temp_xaxis.push(xaxis[index[t]])
        }
        xaxis = temp_xaxis

        // end
        var errorOri = Object.values(arrayData[2])
        errorOri = errorOri.slice(0, 53)
        var temp_errorOri = []
        for (var e = 0, len3 = index.length; e < len3; e++) {
          temp_errorOri.push(errorOri[index[e]])
        }
        errorOri = temp_errorOri

        let errorData = []
        for (var i = 0, l = errorOri.length; i < l; i++) {
          errorData.push(
            [i, yaxis[i] - parseFloat(errorOri[i]) / 2, yaxis[i] + parseFloat(errorOri[i]) / 2, errorOri[i]]
          )
        }

        myecharts.setOption({
          title: {
            text: 'Experimental Methane Yield'
          },
          tooltip: {
            trigger: 'axis'
          },
          // legend: {
          //     orient: 'horizontal',
          //     x: 'left',
          //     y: 'top',
          //     data: ['Experimental Methane Yie', '预期', '实际']
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '80',
            containLabel: true
          },

          xAxis: {
            type: 'category',
            name: "Digestion time (d)", //坐标名字
            nameLocation: "middle",//坐标位置，支持start,end，middle
            nameTextStyle: {//字体样式
              fontSize: 16,//字体大小
              padding: 20 //距离坐标位置的距离
            },
            grid: {
              bottom: 80 //增加柱形图纵向的高度
            },
            boundaryGap: false,
            data: xaxis,
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 14      //更改坐标轴文字大小
              }
            },//['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7']
          },
          yAxis: {
            type: 'value',
            name: "Experimental Methane Yield (mL/gVS)",
            nameLocation: "middle",//坐标位置，支持start,end，middle

            nameTextStyle: {//字体样式

              fontSize: 16,//字体大小

              padding: 20 //距离坐标位置的距离

            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 14      //更改坐标轴文字大小
              }
            },
          },
          series: [
            {
              name: 'EMY',
              type: 'line',
              stack: 'Total',
              data: yaxis//[120, 132, 101, 134, 90, 230, 210]
            },
            {
              type: 'custom',
              name: 'Standard Error',
              itemStyle: {
                normal: {
                  borderWidth: 1
                }
              },
              renderItem: function renderItem(params, api) {
                var xValue = api.value(0); //api.value(0) 表示取出当前 dataItem 中第一个维度的数值。
                var highPoint = api.coord([xValue, api.value(1)]); //高点
                var lowPoint = api.coord([xValue, api.value(2)]); //低点
                // var halfWidth = api.size([1, 0])[0] * 0.1; //半宽度
                var halfWidth = 10;
                var style = api.style({
                  stroke: api.visual('color'),
                  fill: null
                });

                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1]
                      },
                      style: style
                    },
                    {
                      type: 'line',
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1]
                      },
                      style: style
                    },
                    {
                      type: 'line',
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1]
                      },
                      style: style
                    }
                  ]
                };
              },
              encode: { //可以定义 data 的哪个维度被编码成什么
                x: 0,// data 中『维度0』对应到 X 轴
                y: [3] // data 中『维度1』和『维度2』对应到 Y轴
              },
              data: errorData,
              z: 100
            }
          ]
        });
      }

    },
    handleCheckAllChange(val) {
      var data_ = toRaw(this.dataOne)
      let validData = Object.keys(data_)
      if (validData.length === 0) {
        ElMessage({
          type: 'info',
          message: 'There is no data, Please search!',
        })
        return
      }
      this.checkedVarx1 = val ? (this.varx) : []
      isIndeterminate.value = false
      //判断选中的项
      this.htmlData = []
      var tempLi = []
      this.checkedVarx1.forEach(function (value) {
        tempLi.push({
          namex: value,
          value: data_[m1.get(value)]
        })
      })
      this.htmlData = tempLi

      this.checkedVarx2 = val ? (this.varx2) : []
      this.htmlData2 = []
      var tempLi2 = []
      this.checkedVarx2.forEach(function (value) {
        tempLi2.push({
          namex: value,
          value: data_[m1.get(value)]
        })
      })
      this.htmlData2 = tempLi2

      if (!val) {
        this.dataTitle = "EXPERIMENTAL DATA 1"
        this.dataTitle2 = "EXPERIMENTAL DATA 2"
        //if 判断是否有数据数据，设置
        this.isDisable = true
      }
      else {
        this.isDisable = false
      }
    },
    handleCheckedVarxChange(value) {
      if (this.thsAreaCode.length === 0) {
        ElMessage({
          type: 'info',
          message: 'Please select sample!',
        })
        return
      }

      var data_ = toRaw(this.dataOne)
      let validData = Object.keys(data_)
      if (validData.length === 0) {
        this.getecharts(this.thsAreaCode)
      }
      if (validData.length === 0) {
        return
      }
      this.htmlData = [] //重置为空
      var tempList1 = []
      this.checkedVarx1.forEach(function (value) { //根据复选框选中的值更新表中数据
        tempList1.push({
          namex: value,
          value: data_[m1.get(value)]
        })
      })
      this.htmlData = tempList1

      if (value.length > 0) {
        //this.dataTitle = "EXPERIMENTAL DATA 1"
        //if 判断是否有数据数据，设置
        this.isDisable = true
      }
      else {
        this.isDisable = false
      }
    },
    handleCheckedVarxChange2(value) {
      if (this.thsAreaCode.length === 0) {
        ElMessage({
          type: 'info',
          message: 'Please select sample!',
        })
        return
      }
      var data_ = toRaw(this.dataOne)
      let validData = Object.keys(data_)
      if (validData.length === 0) {
        this.getecharts(this.thsAreaCode)
      }
      if (validData.length === 0) {
        // ElMessage({
        //     type: 'info',
        //     message: 'There is no data, Please search!',
        // })
        return
      }
      this.htmlData2 = [] //重置为空
      var tempList2 = []
      this.checkedVarx2.forEach(function (value) { //根据复选框选中的值更新表中数据
        tempList2.push({
          namex: value,
          value: data_[m1.get(value)]
        })
      })
      this.htmlData2 = tempList2

      if (value.length > 0) {
        //this.dataTitle2 = "EXPERIMENTAL DATA 2"
        //if 判断是否有数据数据，设置
        this.isDisable = true
      }
      else {
        this.isDisable = false
      }
    }

  }
}

</script>


<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>